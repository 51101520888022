.contactUsPage {
  padding: 50px 0;
  background-image: url(../../assets/images/bg.jpg);
  position: relative;
  z-index: 1;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: -1;
  }
  .line {
    display: flex;
    flex-direction: column;
    .title,
    .desc {
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      margin: 0;
      padding: 5px 0;
      text-decoration: none;
      color: #ffffff;
    }
    .title {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 5px;
      line-height: 1;
      span {
        font-weight: 700;
      }
      .logo-wrapper {
        width: 20px;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }
    .desc {
      transition: all 150ms ease-in-out;
      &:hover {
        transform: scale(1.01);
        font-weight: 700;
      }
    }
  }
}
