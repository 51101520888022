.navbar {
  background-color: #383e42;
  height: 80px;
  .navbar-brand {
    cursor: pointer;
    img {
      width: 100px;
      height: auto;
      max-height: 64px;
    }
  }
  .navbar-toggler {
    border: none !important;
    outline: none !important;
    box-shadow: none;
  }
  .navbar-collapse {
    z-index: 99;
    background: #383e42;
    padding: 20px;
    border-radius: 5px;
  }
}
.navlinks {
  a {
    text-decoration: none;
    color: #ffffff;
    transition: all 150ms ease-in-out;
    &:hover {
      font-weight: 700;
      transform: scale(1.05);
      @media (max-width: 991px) {
        transform: none;
      }
    }
    &.active {
      font-weight: 700;
      transform: scale(1.05);
      @media (max-width: 991px) {
        transform: none;
      }
    }
  }
}
