.projectsPage {
  padding: 50px 0;
  background-color: rgba(18, 18, 18, 1);
  color: #ffffff !important;
  .info {
    .title {
      font-weight: bold;
      font-size: 28px;
    }
    .description {
      font-size: 14px;
    }
    .sizes {
      margin-bottom: 0;
      font-style: italic;
      font-size: 14px;
    }
    .on {
      margin-bottom: 0;
      font-style: italic;
      font-size: 14px;
    }
  }
}
