.homepage {
  background-color: rgba(18, 18, 18, 1);
  color: #ffffff !important;
  .section-title {
    font-size: 32px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 25px;
  }
  .row-text {
    text-align: justify;
    margin: 0;
    padding: 0 25px;
    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
    @media (max-width: 575px) {
      padding: 0;
      font-size: 14px;
    }
  }
  .row-img-wrapper {
    width: 100%;
    padding: 0 25px;
    @media (max-width: 575px) {
      padding: 0;
    }
  }
  .section1 {
    .background-image {
      width: 100%;
      height: calc(100vh - 100px);
      background-image: url(../../assets/images/homepage-bg1.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: left;
      position: relative;
      .text-wrapper {
        position: absolute;
        top: 50%;
        left: 65%;
        transform: translate(-35%, -50%);
        max-width: 500px;
        width: 100%;
        .text-big {
          margin: 0;
          color: #ffffff;
          font-size: 48px;
          span {
            color: #d36020;
            font-weight: 700;
          }
        }
        .text-small {
          margin: 0;
          font-size: 16px;
          color: white;
        }
        @media (max-width: 991px) {
          left: 60%;
          transform: translate(-40%, -50%);
        }
        @media (max-width: 767px) {
          left: unset;
          right: 20px;
          transform: translate(0, -50%);
          .text-big {
            text-align: right;
          }
        }
        @media (max-width: 575px) {
          max-width: calc(100vw - 40px);
          top: unset;
          bottom: 30px;
          transform: unset;
          .text-big {
            font-size: 32px;
          }
          .text-small {
            font-size: 14px;
          }
        }
      }
    }
  }
  .section2 {
    padding: 30px 0;
  }
}
